import type { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import type { OrderSummaryProps } from './types';
import OrderItemsList from '../order-items-listing/order-items-list';
import Costs from '../order-payment-section/components/costs';
import DiscountForm from '../order-payment-section/components/discount-form';

const OrderSummary: FC<OrderSummaryProps> = ({
    title,
    className,
    dataReference,
    includeItemsList,
    isThanksPage,
    isCartPage,
    asSkeleton,
    hasOutOfStockItems,
    isEmpty,
    ...props
}) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

    const itemsListClassName = useClassNames([props.classNames?.itemsList]);
    const lineItems = dataReference === 'cart' ? props.cart?.lineItems ?? [] : props.order?.lineItems;

    if (asSkeleton && !isCartPage) {
        return (
            <div className={className}>
                <div className="border-checkout-border mb-16 py-16 lg:border-b lg:pt-0">
                    <Skeleton className="h-30 w-full" />
                </div>
                <div className="border-checkout-border mb-16 py-16 lg:border-b lg:pt-0">
                    <Skeleton className="h-80 w-full" />
                </div>
                <Skeleton className="h-30" />
            </div>
        );
    }

    return (
        <div className={className}>
            {title && (
                <div className="border-checkout-border border-b py-16 lg:pt-0">
                    <Typography className="text-16 md:text-18" as="h4">
                        {title}
                    </Typography>
                </div>
            )}
            {includeItemsList && lineItems && <OrderItemsList className={itemsListClassName} lineItems={lineItems} />}
            {!isThanksPage && <DiscountForm className={props.classNames?.applyDiscountButton} />}
            <Costs
                className="border-checkout-border border-t pb-24"
                order={props.order}
                dataReference={dataReference}
                isCartPage={isCartPage}
            />

            {!isThanksPage && props.button}
            {!isThanksPage && hasOutOfStockItems && (
                <Typography className="mt-8 text-14 text-red-500">
                    {formatCartMessage({ id: 'cart.outOfStock', defaultMessage: 'Some items are out of stock' })}
                </Typography>
            )}
            {!isEmpty && isCartPage && (
                <p className="mt-15 hidden text-md md:block">
                    {formatCartMessage({
                        id: 'tax.disclaimer',
                        defaultMessage: 'The tax amount will be calculated on the Payment step of the Checkout'
                    })}
                </p>
            )}
        </div>
    );
};

export default OrderSummary;
