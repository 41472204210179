'use client';

import CybersourceProvider from 'providers/cybersource';
import useCybersourceSettings from 'frontastic/hooks/useCybersourceSettings';
import useCheckoutSettings from 'frontastic/hooks/useStudioCheckoutSettings';
import CheckoutPaymentProvider from './payment';

interface CheckoutProvidersProps {
    children: React.ReactNode;
}

export const CheckoutProviders = ({ children }: React.PropsWithChildren<CheckoutProvidersProps>) => {
    const cybersourceSettings = useCybersourceSettings();
    const checkoutSettings = useCheckoutSettings();
    return (
        <CybersourceProvider cybersourceSettings={cybersourceSettings}>
            <CheckoutPaymentProvider checkoutSettings={checkoutSettings}>{children}</CheckoutPaymentProvider>
        </CybersourceProvider>
    );
};

export default CheckoutProviders;
