import { useMemo } from 'react';
import type { Account } from '@wilm/shared-types/account';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import * as yup from 'yup';
import { useAccount } from 'frontastic';
import type { Address } from '../components/steps/sections/addresses/types';

const useAddress = () => {
    const { account } = useAccount();

    const addressValidationSchema = useMemo(() => {
        return yup.object().shape({
            line1: yup.string().required(),
            line2: yup.string().optional(),
            postalCode: yup.string().required(),
            city: yup.string().required(),
            phone: yup.string().required()
        });
    }, []);

    const checkIfShippingAddressIsValid = useMemo(
        () => (shippingAddress: Address) => addressValidationSchema.isValidSync(shippingAddress),
        [addressValidationSchema]
    );

    const checkIfBillingAddressIsValid = useMemo(
        () => (billingAddress: Address) => {
            console.info('---> account?.isB2B', account?.isB2B);

            if (account?.isB2B) {
                return true;
            }
            return addressValidationSchema.isValidSync(billingAddress);
        },
        [account, addressValidationSchema]
    );

    const isValidAddressForPaymentMethod = useMemo(
        () => (address: Address, paymentMethod: PaymentMethodType, account: Account) => {
            const addressWithAccount = {
                ...address,
                firstName: address.firstName || account.firstName,
                lastName: address.lastName || account.lastName,
                phone: address.phone || account.phoneMobile
            };

            switch (paymentMethod) {
                case PaymentMethodType.CARD:
                    return addressValidationSchema.isValidSync(addressWithAccount);
                case PaymentMethodType.INVOICE:
                case PaymentMethodType.INSTALMENT:
                    return checkIfBillingAddressIsValid(address);
                default:
                    return false;
            }
        },
        [addressValidationSchema, checkIfBillingAddressIsValid]
    );

    return { checkIfBillingAddressIsValid, checkIfShippingAddressIsValid, isValidAddressForPaymentMethod };
};

export default useAddress;
